import { Footer, FrequentLinkCard, Header, SearchBar } from 'components/';
import Image from 'next/image';
import { withRouter } from 'next/router';
import { useEffect } from 'react';

const links = [
  {
    to: '/inmuebles?estados=Ciudad+de+México&alcaldias=Cuajimalpa+de+Morelos',
    image: '/images/Cuajimalpa.png',
    title: 'Cuajimalpa',
  },
  {
    to: '/inmuebles?estados=Ciudad+de+México&alcaldias=Miguel+Hidalgo',
    image: '/images/Miguel.png',
    title: 'Miguel Hidalgo ',
  },
  {
    to: '/inmuebles?estados=Ciudad+de+México&alcaldias=Álvaro+Obregón',
    image: '/images/Alvaro.png',
    title: 'Álvaro Obregón',
  },
  {
    to: '/inmuebles?estados=Estado+de+México&alcaldias=Huixquilucan',
    image: '/images/Huixquilucan.png',
    title: 'Huixquilucan',
  },
  {
    to: '/inmuebles?estados=Estado+de+México&alcaldias=Naucalpan+de+Juárez',
    image: '/images/naucalpan.jpg',
    title: 'Naucalpan',
  },
  {
    to: '/inmuebles?estados=Ciudad+de+México&alcaldias=Benito+Juárez',
    image: '/images/benito juarez.jpg',
    title: 'Benito Juárez',
  },
  {
    to: '/inmuebles?estados=Ciudad+de+México&alcaldias=Cuauhtémoc',
    image: '/images/cuauhtémoc.jpg',
    title: 'Cuauhtémoc',
  },
  {
    to: '/inmuebles?estados=Ciudad+de+México&alcaldias=Coyoacán',
    image: '/images/coyoacan.jpg',
    title: 'Coyoacán',
  },
];

const Home = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      content_name: 'Odigós',
      content_category: 'home',
    });
  }, []);
  return (
    <>
      <div className='home'>
        <div className='flex flex-col min-h-screen bg-no-repeat bg-cover flex-nowrap bg-main'>
          <Header variant='transparent' />
          <Image src='/images/bg.jpg' alt='home page background' layout='fill' className='-z-1' objectFit={'cover'} />
          <div className='flex flex-col items-center h-screen gap-16 '>
            <div className='text-white mt-28 sm:mt-auto'>
              <h1 className='text-6xl text-center text-shadow-black font-futura-med sm:text-left'>Encuentra tu Espacio.</h1>
              <p className='ml-2 text-xl text-center font-futura-light sm:text-left'>Empieza tu busqueda con un concierge inmobiliario</p>
            </div>
            <SearchBar className='mb-auto ' loggedIn={undefined} />
          </div>
        </div>
        <div className='px-4 py-12 text-center home__links-section text-primary font-Champagne'>
          <h2 className='mb-8 text-xl md:text-2xl lg:text-4xl'>Explora espacios cerca de tí</h2>
          <div className='home__frequent-links'>
            <FrequentLinkCard.List items={links} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(Home);
